export const Donate = () => (
    <div className="flex flex-col items-center h-full w-full">
      <div className="flex flex-col items-start justify-start mt-2 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
        <div className="flex flex-col items-center justify-center">
          <img src="/imgs/fp_penguin.png" alt="" className="h-32 w-36 ml-1" />
          <h1 className="font-bold"> We love the pets </h1>
          <div className="flex flex-col items-start">
            <div className="flex items-center mt-2 mb-2">
              <img src="/imgs/paw.svg" alt="" className="ml-1 mr-1 mb-[1.95rem] h-4 w-4" />
              <h2 className="text-xs font-semibold">fpanalytica will never be locked behind a paywall. <br /> If you wish to support operation costs or support in general, any amount helps</h2>
            </div>
            <div className="flex items-center mt-2 mb-2">
              <img src="/imgs/paw.svg" alt="" className="ml-1 mr-1 h-4 w-4 mb-4" />
              <h2 className="text-xs font-semibold">fpanalytica.eth  0xf88887Be20965Dd77afD4433a2F4E8Dd8D052011</h2>
            </div>
            <div className="flex items-center mt-2 mb-2">
              <img src="/imgs/paw.svg" alt="" className="ml-1 mr-1 h-4 w-4" />
              <h2 className="text-xs font-semibold">Thank you and keep your pets alive</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  