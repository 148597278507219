export const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-full w-full">
        <div className=" flex flex-col items-center justify-center mt-2  rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
            <div className="flex flex-col items-center justify-center">
                <a href={`https://frenpet.xyz/`} target="_blank" rel="noreferrer">
                    <img src="/imgs/fp_penguin.png" alt="" className="h-32 w-36" />
                </a>
                <h1 className="font-bold"> Fren Pet Analytica  </h1>
                <div className="flex flex-row">
                <img src="/imgs/paw.svg" alt="" className="mr-[0.3rem] mt-2 h-4 w-4" />
                <h2 className="text-sm font-semibold mt-2"> Your Fren Pet data</h2>
                <img src="/imgs/paw.svg" alt="" className="mr-[0.3rem] ml-1 mt-2 h-4 w-4" />
                </div>
            </div>
            <p className="text-sm mt-3  text-center">To view pets, stats, and balances, enter your wallet address</p>
        </div>
    </div>
  );