import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";
import { OWNERS } from "../../helpers/helpers";
import { addChallengePet, getChallengePets, removeChallengePet } from "../../helpers/utils";

export function ChallengeLeaderboard({
  topPets,
  setTopPets,
  walletAddress,
}: {
  topPets: Pet[];
  setTopPets: (topPets: Pet[]) => void;
  walletAddress: string;
}) {
  const [sortedPets, setSortedPets] = useState<Pet[]>([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const sorted = [...topPets].sort((a, b) => b.score - a.score);
    setSortedPets(sorted);
  }, [topPets]);

  function addChallengePetHandler() {
    try {
      const petId = parseInt(inputValue);
      addChallengePet(petId).then(() => {
        getChallengePets().then((data) => setTopPets(data));
        setInputValue("");
      });
    } catch (e) {
      console.error(e);
    }
  }

  function removeChallengePetHandler() {
    try {
      const petId = parseInt(inputValue);
      removeChallengePet(petId).then(() => {
        getChallengePets().then((data) => setTopPets(data));
        setInputValue("");
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <ul className="mb-2 flex h-screen flex-col">
        {sortedPets.map((pet, index) => (
          <li key={pet.id} className="flex flex-col">
            <div className="flex flex-col">
              <PetStatCard pet={pet} />
            </div>
            <div className="flex flex-row justify-center ">
              <div className="mb-3 flex">
                <img src="/imgs/rank.svg" alt="" className="ml-2 mt-1 h-5 w-5" />
                <div className="ml-1 mt-1"> {index + 1}</div>
              </div>
            </div>
          </li>
        ))}
        {OWNERS.includes(walletAddress) && (
          <div className="flex w-full flex-col items-center">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full rounded-md border-2 border-brown p-1"
            />
            <div className="flex flex-row justify-center space-x-2">
              <button
                onClick={addChallengePetHandler}
                className="mt-2 rounded-md border-2 border-brown bg-white p-1 text-brown w-28"
              >
                Add Pet
              </button>
              <button
                onClick={removeChallengePetHandler}
                className="mt-2 rounded-md border-2 border-brown bg-white p-1 text-brown w-28"
              >
                Remove Pet
              </button>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
}
