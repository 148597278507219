import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";

export function TopOldPetsLeaderboard({
  topPets,
}: {
  topPets: Pet[];
}) {
  const [sortedPets, setSortedPets] = useState<Pet[]>([]);

  useEffect(() => {
    const sorted = [...topPets].sort((a, b) => b.petAgeInDays - a.petAgeInDays);
    setSortedPets(sorted);
  }, [topPets]);

  return (
    <div className="flex">
      <ul className="mb-2 flex h-screen w-[90%] flex-col">
        {sortedPets.map((pet, index) => (
          <li key={pet.id} className="flex flex-col">
            <div className="flex flex-col">
              <PetStatCard pet={pet} />
            </div>
            <div className="flex flex-row justify-center ">
              <div className="mb-3 ml-10 flex">
                <img
                  src="/imgs/rank.svg"
                  alt=""
                  className="ml-2 mt-1 h-5 w-5"
                />
                <div className="ml-1 mt-1"> {index + 1}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
