import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom";
import {
  Pet,
  GasPrice,
  FpPrice,
  Reward,
  Totals,
  Score,
  TotalCats,
  PetRewards,
  PetScores,
  GetPetsResponse,
  EthPrice,
} from "../common/types";
import {
  getTopPets,
  getGasPrices,
  getAllCats,
  getFpPrices,
  getTopAttack,
  getTopDefense,
  getTopOldPets,
  getChallengePets,
  getEthBalance,
  getPgoldBalance,
  getFpBalance,
  wssURL,
  getEthPrices,
  getTopListings,
  getCacheExpiryTime,
} from "./helpers/utils";
import { TopPetsLeaderboard } from "./components/leaderboards/TopPetsLeaderboard";
import { TopCatsLeaderboard } from "./components/leaderboards/TopCatsLeaderboard";
import { PetStatOwnerCard } from "./components/cards/PetStatOwnerCard";
import { TopBar } from "./components/utils/TopBar";
import { PetTimerCard } from "./components/cards/PetTimerCard";
import { Charts } from "./viewers/Charts";
import { TopAttackLeaderboard } from "./components/leaderboards/TopAttackLeaderboard";
import { TopDefenseLeaderboard } from "./components/leaderboards/TopDefenseLeaderboard";
import { TopOldPetsLeaderboard } from "./components/leaderboards/TopOldPetsLeaderboard";
import { ChallengeLeaderboard } from "./components/leaderboards/ChallengeLeaderboard";
import { ActivityFeed } from "./components/views/ActivityFeed";
import { EmptyState } from "./components/views/EmptyState";
import { Legend } from "./components/views/Legend";
import { Donate } from "./components/views/Donate";
import { PetStore } from "./components/views/PetStore";
import { calcCarrotBalance, calculateStaked, refreshPetsAndTotals, refreshCats, refreshChallenge, refreshTopAttack, refreshTopDefense, refreshTopOldPets, refreshTopPets } from "./helpers/helpers";


const VERSION = "1.1";

function App() {
  return (
    <Router> 
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:walletAddress" element={<Main />} />
      </Routes>
    </Router>
  );
}

function Main() {
  const { walletAddress } = useParams();
  const navigate = useNavigate(); 

  const [pets, setPets] = useState<Pet[]>([]);
  const [topPets, setTopPets] = useState<Pet[]>([]);
  const [topCats, setTopCats] = useState<Pet[]>([]);
  const [topAttack, setTopAttack] = useState<Pet[]>([]);
  const [topDefense, setTopDefense] = useState<Pet[]>([]);
  const [topOldPets, setTopOldPets] = useState<Pet[]>([]);
  const [challengePets, setChallengePets] = useState<Pet[]>([]);
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [petRewards, setPetRewards] = useState<PetRewards>({});
  const [scores, setScores] = useState<Score[]>([]);
  const [petScores, setPetScores] = useState<PetScores>({});
  const [totals, setTotals] = useState<Totals>({
    totalRewards: 0,
    totalPets: 0,
    totalScore: 0,
    totalFpOwed: 0,
    totalFpPerSecond: 0,
    totalStars: 0,
    totalMonsters: 0,
  });
  const [totalCats, setTotalCats] = useState<TotalCats>({
    totalCats: 0,
  });
  const [topListings, setTopListings] = useState<Pet[]>([]);
  const [selectedViewer, setSelectedViewer] = useState("timerViewer");
  const [gasPrices, setGasPrices] = useState<GasPrice[]>([]);
  const [fpPrices, setFpPrices] = useState<FpPrice[]>([]);
  const [ethPrices, setEthPrices] = useState<EthPrice[]>([]);
  const [walletAddressState, setWalletAddressState] = useState<string>(walletAddress || "");
  const [balancesViewIndex, setBalancesViewIndex] = useState(0);
  const [pricesViewIndex, setPricesViewIndex] = useState(0);
  const [statsViewIndex, setStatsViewIndex] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [sortKey, setSortKey] = useState<keyof Pet>("score");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [ethBalance, setEthBalance] = useState<string>("");
  const [pGoldBalance, setPgoldBalance] = useState<string>("");
  const [fpBalance, setFpBalance] = useState<string>("");
  const [startY, setStartY] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPulling, setIsPulling] = useState(false);
  const [hibernatedData, setHibernatedData] = useState<any[]>([]);
  const [bonkData, setBonkData] = useState<any[]>([]);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [startXContent, setStartXContent] = useState<number | null>(null);
  const [isWalletDataLoaded, setIsWalletDataLoaded] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [cacheExpiryTime, setCacheExpiryTime] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const cacheExpiryIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const hideOnView = [
    "toppets",
    "topattack",
    "topdefense",
    "charts",
    "topcats",
    "challenge",
    "activityfeed",
    "legend",
    "donate",
    "topoldpets",
    "store"
  ].includes(selectedViewer);

  const SWIPE_THRESHOLD = 50;

  const ethPrice = ethPrices.length > 0 ? ethPrices[ethPrices.length - 1].eth_price : 0;
  const totalRewardsInUsd = (totals.totalRewards * ethPrice).toFixed(2);
  const ethBalanceFloat = parseFloat(ethBalance);
  const totalEthBalInUsd = isNaN(ethBalanceFloat) ? 0 : (ethBalanceFloat * ethPrice).toFixed(2);
  const fpPrice = fpPrices.length > 0 ? fpPrices[fpPrices.length - 1].fp_price : 0;
  const fpBalanceFloat = parseFloat(fpBalance);
  const fpBalanceInUsd = isNaN(fpBalanceFloat) ? 0 : (fpBalanceFloat * fpPrice).toFixed(2);
  const totalStakedFp = calculateStaked(totals.totalFpPerSecond);
  const totalStakedFpInUsd = (totalStakedFp * fpPrice).toFixed(2);
  const initialBalances = useMemo(() => {
    if (walletAddress) {
      getEthBalance(walletAddress).then((balance) => {
        setEthBalance(parseFloat(balance).toFixed(4));
      });
      getPgoldBalance(walletAddress).then((balance) => {
        setPgoldBalance(parseFloat(balance).toFixed(0));
      });
      getFpBalance(walletAddress).then((balance) => {
        setFpBalance(parseFloat(balance).toFixed(2));
      });
    }
  }, [walletAddress]);
  const sortKeyRef = useRef(sortKey);
  const sortOrderRef = useRef(sortOrder);
  const totalCarrots = pets.reduce((total, pet) => {
    return total + calcCarrotBalance(pet.carrotsBalance, pet.carrotsTimestamp);
  }, 0);

  const incrementBalancesViewIndex = () => {
    setBalancesViewIndex((prevIndex) => (prevIndex + 1) % 9);
  };

  const decrementBalancesViewIndex = () => {
    setBalancesViewIndex((prevIndex) => (prevIndex === 0 ? 8 : prevIndex - 1));
  };

  const incrementPricesViewIndex = () => {
    setPricesViewIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  const decrementPricesViewIndex = () => {
    setPricesViewIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const incrementStatsViewIndex = () => {
    setStatsViewIndex((prevIndex) => (prevIndex + 1) % 5);
  };

  const decrementStatsViewIndex = () => {
    setStatsViewIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
  };


  const handleContentTouchStart = (e: TouchEvent) => {
    const touchStartX = e.touches[0].clientX;
    setStartXContent(touchStartX);
  };

  const handleContentTouchMove = (e: TouchEvent) => {
    const touchEndX = e.touches[0].clientX;
    if (startXContent !== null) {
      const diffX = startXContent - touchEndX;
      if (diffX > SWIPE_THRESHOLD) {
        incrementBalancesViewIndex();
        setStartXContent(null);
      } else if (diffX < -SWIPE_THRESHOLD) {
        decrementBalancesViewIndex();
        setStartXContent(null);
      }
    }
  };

  const handleContentTouchEnd = () => {
    setStartXContent(null);
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    decrementBalancesViewIndex();
  };

  const handlePricesMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    decrementPricesViewIndex();
  };

  const handleStatsMenu =  (e: React.MouseEvent) => {
    e.preventDefault();
    decrementStatsViewIndex();
  };

  const handleRefreshCats = () => {
    setIsLoading(true);
    refreshCats(setTopCats, setTotalCats);
    setIsLoading(false);
  };

  const handleRefreshChallenge = () => {
    setIsLoading(true);
    refreshChallenge(walletAddressState, setChallengePets);
    setIsLoading(false);
  }

  const handleRefreshTopPets = () => {
    setIsLoading(true);
    refreshTopPets(walletAddressState, setTopPets);
    setIsLoading(false);
  }

  const handleRefreshTopAttack = () => {
    setIsLoading(true);
    refreshTopAttack(walletAddressState, setTopAttack);
    setIsLoading(false);
  }

  const handleRefreshTopDefense= () => {
    setIsLoading(true);
    refreshTopDefense(walletAddressState, setTopDefense);
    setIsLoading(false);
  }

  const handleRefreshTopOldPets = () => {
    setIsLoading(true);
    refreshTopOldPets(walletAddressState, setTopOldPets);
    setIsLoading(false);
  }

  const pull = (e: TouchEvent) => {
    if (startY !== null) {
      const currentY = e.touches[0].clientY;
      if (currentY - startY > 60) {
        setIsPulling(true);
      }
    }
  };

  const pullStart = (e: TouchEvent) => {
    if (window.scrollY === 0) {
      setStartY(e.touches[0].clientY);
    } else {
      setStartY(null);
    }
  };

  const endPull = (e: TouchEvent) => {
    const refreshMapping: { [key: string]: () => void } = {
      topcats: handleRefreshCats,
      challenge: handleRefreshChallenge,
      toppets: handleRefreshTopPets,
      topattack: handleRefreshTopAttack,
      topdefense: handleRefreshTopDefense,
      topoldpets: handleRefreshTopOldPets,
    };

    if (isPulling) {
      if (selectedViewer === "timerViewer" || selectedViewer === "statViewer") {
        setRefreshTrigger((prev) => prev + 1);
      }
      if (selectedViewer in refreshMapping) {
        refreshMapping[selectedViewer]();
      }
      setIsPulling(false);
    }
    setStartY(null);
  };

  const handleWalletAddressChange = (address: string) => {
    setWalletAddressState(address);
    navigate(`/${address}`); 
  };

  function setupWebSocket() {
    const newWs = new WebSocket(wssURL);
    newWs.onopen = () => {
      console.log("Connected to WebSocket");
      setWs(newWs);
      setIsLoading(false);
    };
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "ownerData") {
        const walletAddress = message.data.wallet;
        setIsLoading(true);
        refreshPetsAndTotals(
          walletAddress,
          sortKeyRef.current,
          sortOrderRef.current,
          setPets,
          setRewards,
          setScores,
          setTotals,
          setPetRewards,
          setPetScores,
          setEthBalance,
          setPgoldBalance,
          setFpBalance,
          message.data,
        );
        setIsLoading(false);
        setIsWalletDataLoaded(true);
      } else if (message.type === "hibernated" || message.type === "killed") {
        setHibernatedData((prevData) => [...prevData, message.data]);
      } else if (message.type === "attack") {
        setBonkData((prevData) => [...prevData, message.data]);
      } else if (message.type === "sessionId") {
        setSessionId(message.data);
        console.log("Assigned sessionId:", message.data);
      } else if (message.type === "loading") {
        setIsLoading(true);
      }
    };
    newWs.onclose = () => {
      console.log("WebSocket closed");
      setTimeout(() => {
        setupWebSocket();
      }, 1000);
    };
  }

  useEffect(() => {
    window.addEventListener("touchstart", handleContentTouchStart);
    window.addEventListener("touchmove", handleContentTouchMove);
    window.addEventListener("touchend", handleContentTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleContentTouchStart);
      window.removeEventListener("touchmove", handleContentTouchMove);
      window.removeEventListener("touchend", handleContentTouchEnd);
    };
  }, [startXContent]);


  useEffect(() => {
    getGasPrices().then(setGasPrices);
    const gasInterval = setInterval(() => {
      getGasPrices().then(setGasPrices);
    }, 60000);

    getFpPrices().then(setFpPrices);
    const fpInterval = setInterval(() => {
      getFpPrices().then(setFpPrices);
    }, 60000);

    getEthPrices().then(setEthPrices);
    const ethInterval = setInterval(() => {
      getEthPrices().then(setEthPrices);
    }, 60000);

    return () => {
      clearInterval(gasInterval);
      clearInterval(fpInterval);
      clearInterval(ethInterval);
    };
  }, []);

  useEffect(() => {
    if (isWalletDataLoaded) {
      setIsLoading(false);
      getTopAttack().then((data) => setTopAttack(data));
      getTopDefense().then((data) => setTopDefense(data));
      getTopOldPets().then((data) => setTopOldPets(data));
      getChallengePets().then((data) => setChallengePets(data));
      getTopPets().then((data) => setTopPets(data));
      getAllCats().then((data) => {
        setTopCats(data.cats);
        setTotalCats({
          totalCats: data.totalCats,
        });
        setTotals((prev) => ({ ...prev }));
      });
      getTopListings().then((data) => setTopListings(data));
    }
  }, [isWalletDataLoaded]);
  


  useEffect(() => {
    sortKeyRef.current = sortKey;
    sortOrderRef.current = sortOrder;
  }, [sortKey, sortOrder]);


  useEffect(() => {
    if (walletAddress) {
      console.log(`Creating WebSocket`);
      setupWebSocket();
      setIsLoading(true);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress && ws) {
      if (!sessionId) {
        ws.send(JSON.stringify({ type: "getSessionId", version: VERSION }));
        return;
      }
      console.log("Sending wallet address to WebSocket:", walletAddress);
      setIsLoading(true);
      ws.send(
        JSON.stringify({ type: "mainWallet", wallet: walletAddress, version: VERSION, sessionId }),
      );
    }
  }, [walletAddress, ws, sessionId]);

  useEffect(() => {
    if (refreshTrigger > 0 && ws && ws.readyState === WebSocket.OPEN && sessionId) {
      setIsLoading(true);
      console.log("Refreshing via ws:", walletAddress);
      ws.send(JSON.stringify({ type: "refresh", wallet: walletAddress, version: VERSION, sessionId}));
    }
  }, [refreshTrigger, ws, sessionId, walletAddress]);  

  useEffect(() => {
    setSortKey("score");
    setSortOrder("desc");
  }, [walletAddress]);


  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  }, [startY, isPulling]);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 500);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCacheExpiryTime = () => {
      getCacheExpiryTime().then((expiryTime) => {
        setCacheExpiryTime(expiryTime);
        let timeLeft = expiryTime / 1000;
        setRemainingTime(timeLeft);
        const expiryInSeconds = expiryTime / 1000;

        if (expiryInSeconds === cacheExpiryTime / 1000) {
          setTimeout(() => fetchCacheExpiryTime(), 5000);
          return;
        }
  
        setCacheExpiryTime(expiryTime);
        setRemainingTime(expiryInSeconds);
  
        if (cacheExpiryIntervalRef.current) {
          clearInterval(cacheExpiryIntervalRef.current);
        }
  
        cacheExpiryIntervalRef.current = setInterval(() => {
          setRemainingTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(cacheExpiryIntervalRef.current!);
              fetchCacheExpiryTime();
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      });
    };
  
    fetchCacheExpiryTime();
  
    return () => {
      if (cacheExpiryIntervalRef.current) {
        clearInterval(cacheExpiryIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="h-full w-full bg-green font-sans">
      <TopBar
        setPets={setPets}
        setTotals={setTotals}
        selectedViewer={selectedViewer}
        setSelectedViewer={setSelectedViewer}
        walletAddress={walletAddressState}
        setWalletAddress={handleWalletAddressChange}
        setTopCats={setTopCats}
        setChallengePets={setChallengePets}
        setRewards={setRewards}
        setScores={setScores}
        setPetRewards={setPetRewards}
        setPetScores={setPetScores}
        setEthBalance={setEthBalance}
        setFpBalance={setFpBalance}
        setPgoldBalance={setPgoldBalance}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefreshTrigger={setRefreshTrigger}
        sortKey={sortKey}
        sortOrder={sortOrder}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        refreshCats={handleRefreshCats}
        refreshChallenge={handleRefreshChallenge}
        refreshTopPets={handleRefreshTopPets}
        refreshTopAttack={handleRefreshTopAttack}
        refreshTopDefense={handleRefreshTopDefense}
        refreshTopOldPets={handleRefreshTopOldPets}
        isPhone={isPhone}
      />
      <div className="overflow-y-auto">
        <div className="mt-[7.5rem] flex flex-col items-center">
          <div className="mb-1 flex max-h-6 items-center justify-center">
            {!hideOnView && (
              <>
                {isPulling && !isLoading && (
                  <img src="/imgs/down-arrow.svg" alt="Pulling" className="h-5 w-5" />
                )}
              </>
            )}
          </div>
          { !isLoading &&
          pets.length === 0 &&
          (selectedViewer === "timerViewer" ||
            selectedViewer === "statViewer" ||
            selectedViewer === "charts") ? (
            <EmptyState />
          ) : (
            <>
              {!hideOnView && (
                <div className="flex flex-col justify-center bg-green text-sm min-h-6 max-h-6">
                  <div className="flex flex-row justify-center">
                    <div className="ml-8 mr-6 w-[3.5rem] flex" onClick={incrementStatsViewIndex} onContextMenu={handleStatsMenu}>
                      {statsViewIndex === 0 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/paw.svg" alt="" className="ml-[.1rem] mt-[.06rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{totals.totalPets}</div>
                        </div>
                      ) : statsViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/piggy-bank.svg"
                              alt=""
                              className="mr-[0.15rem] ml-[.1rem] mt-[.03rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totals.totalScore.toFixed(0)}
                          </div>
                        </div>
                      ) : statsViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/carrot.svg"
                              alt=""
                              className="mr-[0.2rem] ml-[.1rem] mt-[.025rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totalCarrots ? totalCarrots.toFixed(2) : 0}
                          </div>
                        </div>
                      ): statsViewIndex === 3 ? (
                      <div className="flex max-w-28 items-center">
                        <div className="w-5">
                          <img src="/imgs/pokeball.svg" alt="" className="mt-[.15rem] h-5 w-5" />
                        </div>
                        <div className="ml-[.1rem] mt-[.1rem]">•</div>
                        <div className="ml-[.2rem] mt-[.1rem]">{totals.totalMonsters}</div>
                      </div>
                      ) : statsViewIndex === 4 ? (
                          <div className="flex max-w-28 items-center">
                            <div className="w-5">
                              <img
                                src="/imgs/star.svg"
                                alt=""
                                className="mt-[.06rem] ml-[.12rem] h-4 w-4"
                              />
                            </div>
                            <div className="ml-[.1rem] mt-[.1rem]">•</div>
                            <div className="ml-[.2rem] mt-[.1rem]">{totals.totalStars}</div>
                          </div>
                        ) : null}
                    </div>
                    <div
                      className="ml-9 flex w-[7.9rem]"
                      onClick={incrementBalancesViewIndex}
                      onContextMenu={handleContextMenu}
                    >
                      {balancesViewIndex === 0 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/treasure.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{totals.totalRewards} </div>
                          <img
                            src="/imgs/ethereum.svg"
                            alt=""
                            className="mt-[0.1rem] h-[.7rem] w-[.7rem]"
                          />
                        </div>
                      ) : balancesViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/treasure.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]"> ${totalRewardsInUsd} </div>
                        </div>
                      ) : balancesViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/wallet.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{ethBalance}</div>
                          <img
                            src="/imgs/ethereum.svg"
                            alt=""
                            className="mt-[0.1rem] h-[.7rem] w-[.7rem]"
                          />
                        </div>
                      ) : balancesViewIndex === 3 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/wallet.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]"> ${totalEthBalInUsd}</div>
                        </div>
                      ) : balancesViewIndex === 4 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {fpBalance}
                          </div>
                        </div>
                      ) : balancesViewIndex === 5 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            ${fpBalanceInUsd}
                          </div>
                        </div>
                      ) : balancesViewIndex === 6 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totals.totalFpOwed.toFixed(0)} | {" "}
                            {totalStakedFp}
                          </div>
                        </div>
                      ) : balancesViewIndex === 7 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            ${totalStakedFpInUsd}
                          </div>
                        </div>
                      ) : balancesViewIndex === 8 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/coin-bag.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{pGoldBalance}</div>
                        </div>
                      )  : null}
                    </div>

                    <div
                      className="ml-2 flex w-[6.6rem] justify-start"
                      onClick={incrementPricesViewIndex}
                      onContextMenu={handlePricesMenu}
                    >
                      {pricesViewIndex === 0 ? (
                        gasPrices.length > 0 && (
                          <div className="flex max-w-28 items-center">
                            <img src="/imgs/gas.svg" alt="" className="mr-[0.25rem] mt-[.1rem] h-4 w-4" />
                            •
                            <div className="ml-[.3rem] mt-[.1rem]">
                              {gasPrices[gasPrices.length - 1].gas_price.toFixed(4)}
                            </div>
                          </div>
                        )
                      ) : pricesViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/frog.svg" alt="" className="mr-[0.25rem] mt-[.1rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem]">
                            ${fpPrices[fpPrices.length - 1].fp_price.toFixed(2)}
                          </div>
                        </div>
                      ) : pricesViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/ethereum.svg" alt="" className="mr-[0.15rem] mt-[.075rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem]">
                            ${ethPrices[ethPrices.length - 1].eth_price.toFixed()}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="mx-auto mb-8 flex w-screen flex-row flex-wrap items-center justify-center">
                {selectedViewer === "timerViewer" &&
                  pets.map((pet) => (
                    <PetTimerCard key={pet.id} pet={pet} walletAddress={walletAddressState} />
                  ))}
                {selectedViewer === "statViewer" &&
                  pets.map((pet) => (
                    <PetStatOwnerCard key={pet.id} pet={pet} walletAddress={walletAddressState} />
                  ))}
                {selectedViewer === "charts" && (
                  <Charts
                    gasPrices={gasPrices}
                    rewards={rewards}
                    fpPrices={fpPrices}
                    scores={scores}
                    petRewards={petRewards}
                    petScores={petScores}
                    ethPrices={ethPrices}
                  />
                )}
                {selectedViewer === "toppets" && (
                  <TopPetsLeaderboard topPets={topPets} walletAddress={walletAddressState} />
                )}
                {selectedViewer === "topcats" && (
                  <TopCatsLeaderboard topCats={topCats} totalCats={totalCats.totalCats} />
                )}
                {selectedViewer === "topattack" && <TopAttackLeaderboard topPets={topAttack} />}
                {selectedViewer === "topdefense" && <TopDefenseLeaderboard topPets={topDefense} />}
                {selectedViewer === "topoldpets" && <TopOldPetsLeaderboard topPets={topOldPets} />}
                {selectedViewer === "challenge" && (
                  <ChallengeLeaderboard
                    topPets={challengePets}
                    setTopPets={setChallengePets}
                    walletAddress={walletAddressState}
                  />
                )}
                {selectedViewer === "activityfeed" && (
                  <ActivityFeed hibernatedData={hibernatedData} bonkData={bonkData} />
                )}
                {selectedViewer === "store" && <PetStore topListings={topListings} isPhone={isPhone} remainingTime={remainingTime} walletAddress={walletAddressState}/>}
                {selectedViewer === "legend" && <Legend />}
                {selectedViewer === "donate" && <Donate />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
