import { GasPrice } from "../../../common/types";
import { ResponsiveContainer, XAxis, YAxis, Line, LineChart, Tooltip } from "recharts";
import { formatDayTime, calculateTicks } from "../../helpers/helpers";
import { useState } from "react";
import { secondsDict } from "../utils/ChartUtils";

export function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${selected ? "bg-brown text-white" : "bg-white text-brown"}`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

export function GasChart({ gasPrices }: { gasPrices: GasPrice[] }) {
  const [selected, setSelected] = useState("1D");

  const data = gasPrices.filter(
    (price) => price.timestamp > Date.now() / 1000 - secondsDict[selected],
  );

  return (
    <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
      <div className="flex w-full justify-between">
        <div className="mb-4font-sans text-xl">Gas</div>
        <div className="mb-4 space-x-1 font-sans text-sm">
          <Button text="1D" selected={selected === "1D"} setSelected={setSelected} />
          <Button text="7D" selected={selected === "7D"} setSelected={setSelected} />
          <Button text="1M" selected={selected === "1M"} setSelected={setSelected} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <XAxis
            dataKey="timestamp"
            type="number"
            tickFormatter={formatDayTime}
            ticks={calculateTicks(data)}
            domain={["dataMin", "dataMax"]}
            stroke="#342e2e"
          />
          <YAxis dataKey="gas_price" stroke="#342e2e" />
          <Line type="monotone" dataKey="gas_price" stroke="#342e2e" dot={false} />
          <Tooltip labelFormatter={formatDayTime} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
